import { Candidat } from "./candidat";
import { TestCandidat } from "./testCandidat";

/**
 * Cette classe représente une invitation pour passer un test
 * L'invitation est associée à un candidat et à un testcandidat
 */
export class Invitation {
  id = ""; // = aussi le token d'accès/identification de l'inviotation lors de son arrivée (via son lien d'accès)
  nom = "";
  prenom = "";
  xp: string | number;
  dateInvitation: Date;
  dateConnexion: Date;
  dateSuppression: Date;
  candidat: Candidat;
  testcandidat: TestCandidat;
  emailStatus: String;
}

class ScoreByCategorie {
  categoryId: number;
  categoryName: string;
  note: number;
  score: number;
  maxScore: number;
}

/**
 * Cette classe représente les données envoyées du Backend pour la génération de PDF
 */
export class PDFData {
  candidateTotalScore: ScoreByCategorie;
  candidateScoreByCategory: [ScoreByCategorie];
  averageByCategory: [ScoreByCategorie];
  questionnaireId: number;
  testsScoreNegatif: number;
  testsScoreInf20: number;
  testsScoreInf40: number;
  testsScoreInf60: number;
  testsScoreInf80: number;
  testsScoreInf100: number;
}
