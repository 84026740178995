import { Router } from '@angular/router';
import { Question } from '../models/question';
import { MembreAptea } from '../models/membreAptea';
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { UserService } from '../service';
import { QuestionService } from '../service/question.service';
import { ViewEncapsulation } from '@angular/core';
import { AuthService } from '../service';
import { MatTableDataSource } from '@angular/material/table';
import { CategorieService } from '../service/categorie.service';
import { ConfirmActionDialogComponent } from '../component/confirm-action-dialog/confirm-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../shared/utilities/Utils';
import { finalize } from 'rxjs/operators';
import { NotyfToast } from '../shared/notify-toast/notify-toast.component';
import { cloneDeep } from 'lodash';
import { MatPaginator } from '@angular/material/paginator';
import { Constants, LabelValue } from '../shared/utilities/Constants';
import { Categorie } from '../models/categorie';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AppTheme } from '../shared/utilities/AppTheme';
import { TranslateService } from '@ngx-translate/core';
import { AppRoles } from '../shared/utilities/AppRoles';
import { InvitationService } from 'app/service/invitation.service';
import { ActivatedRoute } from '@angular/router';
import { QuestionPreviewDialogComponent } from '../component/question-preview-dialog/question-preview-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { QuestionStatsDialogComponent } from '../component/question-stats-dialog/question-stats-dialog.component';

interface Result {
  content: string;
  isVisible: boolean;
  question: Question;
}

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionsComponent implements OnInit {
  authenticatedMember: MembreAptea;
  isAdminOrRh: boolean;
  dataSource: any;

  public flip = false;
  @Input() change: number;

  rows: Question[];
  columnMode = ColumnMode;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentPage = 0;
  pageSize = 25;
  totalElements: number;

  // titleFilter: string;
  // typeFilter: string;
  // categoryFilter: number;
  sort: string;

  questionTypes: LabelValue[] = Constants.QUESTION_TYPES;
  
  categoriesList: Categorie[];

  questionToEditOrDelete: Question;
  questionDialog: Question;
  updatedQuestion: Question;

  showTable = true; // astuce pour contourner le bug cf https://github.com/swimlane/ngx-datatable/issues/1482

  loadingListOfQuestion = false;
  loadingCurrentUser = false;
  dialogConfig: MatDialogConfig = { position: { top: '100px' }, width: '70%' };

  utils = Utils;
  appTheme = AppTheme;

  public form: {
    titleFilter: string;
    typeFilter: string;
    categoryFilter: number;
    pageSize: number;
  };

  public results: Result[];
  private activatedRoute: ActivatedRoute;

  constructor(
    @Inject(UserService) private userService: UserService,
    @Inject(QuestionService) private questionService: QuestionService,
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    @Inject(CategorieService) public categorieService: CategorieService,
    @Inject(InvitationService) public invitationService: InvitationService,
    public matDialog: MatDialog,
    private toastr: ToastrService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.questionService = questionService;
    this.toastr.toastrConfig.maxOpened = 1;

    this.form = {
      titleFilter: activatedRoute.snapshot.params.filter || '',
      typeFilter: activatedRoute.snapshot.params.filter1 || '',
      categoryFilter: activatedRoute.snapshot.params.filter2 || '',
      pageSize: activatedRoute.snapshot.params.filter3 || '',
    };
    this.results = [];
  }

  async ngOnInit() {
    await this.detectCurrentMember();
    await this.paginator.firstPage();
    await this.listOfQuestion();
    await this.listOfCategories();

  }

  getRowClass = (row) => {
    return {
      'bg-success text-white data-table-selected-row':
        this.updatedQuestion && row.id === this.updatedQuestion.id,
      'bg-warning text-white data-table-selected-row':
        this.questionToEditOrDelete &&
        row.id === this.questionToEditOrDelete.id,
    };
  };

  editQuestion(question) {
    if (question.type === 'libre') {
      this.router.navigate(['/updateOuverte', question.id]);
    } else if (question.type === 'choix multiple') {
      this.router.navigate(['/updateChoixMultiple', question.id]);
    } else if (question.type === 'texte') {
      this.router.navigate(['/updateTexte', question.id]);
    } else {
      this.router.navigate(['/updateCode', question.id]);
    }
  }

  validateQuestion(question) {
    if (this.isAdminOrRh) {
      this.validateQuestionAdminOrRh(question);
    }
  }

  validateQuestionAdminOrRh(question: Question) {
    this.questionToEditOrDelete = question;

    const translateVars: any = this.translate.instant(
      'question.validateQuestion'
    );

    const operationName = question.validated
      ? translateVars.operationName.invalidation
      : translateVars.operationName.validation;
    const verbName = question.validated
      ? translateVars.verbName.invalid
      : translateVars.verbName.validate;

    const validateQuestionDialog = this.matDialog.open(
      ConfirmActionDialogComponent,
      {
        data: {
          dialogTitle:
            translateVars.dialogTitle.start +
            operationName +
            translateVars.dialogTitle.end,
          dialogMessage:
            translateVars.dialogMessage.start +
            verbName +
            translateVars.dialogMessage.end,
        },
      }
    );

    validateQuestionDialog.afterClosed().subscribe((actionConfirmed) => {
      this.questionToEditOrDelete = undefined;
      Utils.removeSelectRowStyle();

      if (actionConfirmed) {
        this.loadingListOfQuestion = true;
        this.questionService
          .validateQuestion(question.id, question.version, !question.validated)
          .pipe(
            finalize(() => {
              this.loadingListOfQuestion = false;
            })
          )
          .subscribe(
            (data) => {
              this.refreshAfterUpdate(question, operationName);
            },
            (err) => {
              if (err.status === 412) {
                // precondition failed
                this.toastr.error(
                  this.translate.instant('toastr.error.optimisticLocking')
                );
              } else {
                this.toastr.error(
                  this.translate.instant('toastr.error.validationFailure')
                );
              }
            }
          );
      }
    });
  }

  private sendNotifSuccess(message: string): void {
    const options = cloneDeep(this.toastr.toastrConfig);
    options.toastComponent = NotyfToast;
    options.toastClass = 'notyf confirm';
    this.toastr.success(message, null, options);
  }

  private refreshAfterUpdate(question, operationName: string) {
    this.sendNotifSuccess(operationName);

    this.updatedQuestion = question;
    this.listOfQuestion();

    setTimeout(() => {
      this.updatedQuestion = undefined;

      Utils.removeSelectRowStyle();
    }, 10000);
  }

  deleteQuestion(question) {
    if (this.isAdminOrRh) {
      this.deleteQuestionAdminOrRh(question);
    }
  }

  deleteQuestionAdminOrRh(question) {
    this.questionToEditOrDelete = question;

    const translateVars: any = this.translate.instant(
      'question.deleteQuestion'
    );

    const deleteQuestionDialog = this.matDialog.open(
      ConfirmActionDialogComponent,
      {
        data: {
          dialogTitle: translateVars.dialogTitle,
          dialogMessage: translateVars.dialogMessage,
        },
      }
    );

    deleteQuestionDialog.afterClosed().subscribe((actionConfirmed) => {
      this.questionToEditOrDelete = undefined;
      Utils.removeSelectRowStyle();

      if (actionConfirmed) {
        this.loadingListOfQuestion = true;
        this.questionService
          .deleteQuestion(question)
          .pipe(
            finalize(() => {
              this.loadingListOfQuestion = false;
            })
          )
          .subscribe(
            (request) => {
              this.sendNotifSuccess(translateVars.deleteSuccess);
              this.listOfQuestion();
            },
            (error) => {
              if (error.status === 412) {
                // precondition failed
                this.toastr.error(
                  this.translate.instant('toastr.error.optimisticLocking')
                );
              } else if (error.status === 409) {
                // conflict
                this.toastr.error(
                  this.translate.instant('toastr.error.integrityViolation')
                );
              } else {
                this.toastr.error(translateVars.deleteError);
              }
            }
          );
      }
    });
  }

  async detectCurrentMember() {
    this.loadingCurrentUser = true;
    await this.authService
      .getCurrentMembreAptea()
      .toPromise()
      .then((authenticatedMember) => {
        this.authenticatedMember = authenticatedMember;
        if (
          authenticatedMember.profile === AppRoles.ADMIN ||
          authenticatedMember.profile === AppRoles.RH
        ) {
          this.isAdminOrRh = true;
        }
      })
      .catch((error) => {
        this.router.navigate(['/401']);
      });
    this.loadingCurrentUser = false;
  }

  async listOfQuestion() {
    this.loadingListOfQuestion = true;

    await this.questionService
      .listOfQuestionFromMembre(
        this.currentPage,
        this.form.pageSize,
        this.form.titleFilter,
        this.form.typeFilter,
        this.form.categoryFilter,
        
        this.sort,
      )
      .toPromise()
      .then((response) => {
        this.rows = <Question[]>response.content;
        this.totalElements = response.totalElements;
        this.dataSource = new MatTableDataSource(this.rows);
      })
      .catch((error) => {
        this.toastr.error(
          this.translate.instant('toastr.error.getQuestionsError')
        );
      });
    this.loadingListOfQuestion = false;
  }

  async listOfCategories() {
    await this.categorieService
      .getAllCategories()
      .toPromise()
      .then((categories) => {
        this.categoriesList = categories;
      });
  }

  openQuestionPreview(question) {
    /*
    this.router.navigate(['/' + InvitationRoutes.simulation.repondre], {
      queryParams: { token: question.id },
    });*/

    const questionPreviewDialog = this.matDialog.open(
      QuestionPreviewDialogComponent,
      {
        ...this.dialogConfig,
        data: { question: question },
      }
    );
  }

  onSort(event: any) {
    this.sort = Utils.getSort(event);
    this.listOfQuestion();
  }

  public applyFilters(): void {
    this.paginator.pageIndex = 0;
    this.currentPage = 0;
    this.applyFilterToResults();
    this.applyFilterToRoute();
    this.listOfQuestion();
  }

  setPage(pageInfo) {
    this.currentPage = pageInfo.pageIndex;
    this.form.pageSize = pageInfo.pageSize;
    this.applyFilterToResults();
    this.applyFilterToRoute();
    this.listOfQuestion();
  }

  getValidateQuestionTooltip(question: Question): string {
    const validateQuestionVars = this.translate.instant(
      'question.validationTooltip'
    );
    return this.isAdminOrRh
      ? question.validated
        ? validateQuestionVars.invalidate
        : validateQuestionVars.validate
      : validateQuestionVars.message;
  }

  getDeleteQuestionTooltip(): string {
    const deleteQuestionVars = this.translate.instant('question.deleteTooltip');
    return this.isAdminOrRh
      ? deleteQuestionVars.delete
      : deleteQuestionVars.message;
  }

  userCannotUpdateQuestion(question: Question): boolean {
    return question.validated && !this.isAdminOrRh;
  }

  userCannotGetQuestionStats(question: Question): boolean {
    return question.validated && !this.isAdminOrRh;
  }

  getEditQuestionTooltip(question: Question): string {
    const editQuestionVars = this.translate.instant('question.editionTooltip');
    return this.userCannotUpdateQuestion(question)
      ? editQuestionVars.message
      : editQuestionVars.edit;
  }

  private applyFilterToResults(): void {
    const filter = this.form.titleFilter.toLowerCase();
    const filter1 = this.form.typeFilter.toLowerCase();

    for (const result of this.results) {
      result.isVisible = filter ? result.content.includes(filter) : true;
    }

    for (const result of this.results) {
      result.isVisible = filter1 ? result.content.includes(filter1) : true;
    }
  }

  private applyFilterToRoute(): void {
    this.router.navigate(
      [
        {
          filter: this.form.titleFilter,
          filter1: this.form.typeFilter,
          filter2: this.form.categoryFilter,
          filter3: this.form.pageSize,
        },
      ],
      {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
      }
    );
  }

  openQuestionStats(question) {
    this.questionDialog = question;

    const questionStatsDialog = this.matDialog.open(
      QuestionStatsDialogComponent,
      {
        ...this.dialogConfig,
        data: { questionId: question.id,
        questionType: question.type,
        questionEnonce: question.enonce },
      }
    );

    questionStatsDialog.afterClosed().subscribe((result) => {
      this.questionDialog = undefined;
    });
  }
}
