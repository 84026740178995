<mat-toolbar color="primary">
  <div fxFlex fxLayoutAlign="start">
    <button
      (click)="onClick()"
      mat-icon-button
      matTooltip="{{ translate.instant('invitations.sendQuestionnaire') }}"
    >
      <mat-icon>mail_outline</mat-icon>
      {{ translate.instant("invitations.sendQuestionnaire") }}
    </button>
  </div>

  <div fxFlex fxLayoutAlign="end">
    <button
      [disabled]="!selectedRows || selectedRows.length == 0"
      (click)="onDeleteRow()"
      mat-icon-button
      matTooltip="{{ translate.instant('invitations.deleteInvitations') }}"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div class="ngx-datatable-and-mat-paginator">
  <ngx-datatable
    class="striped ngx-datatable-with-mat-paginator"
    [ngClass]="{
      dark: appTheme.globalTheme === appTheme.dark,
      material:
        appTheme.globalTheme === appTheme.light ||
        appTheme.globalTheme === appTheme.mix
    }"
    [columnMode]="columnMode.force"
    [headerHeight]="50"
    [footerHeight]="0"
    [scrollbarH]="true"
    [rowHeight]="50"
    *ngIf="showTable"
    [loadingIndicator]="loadingListOfInvitation"
    [rowClass]="getRowClass"
    [rows]="rowData"
    (sort)="onSort($event)"
    [selected]="selectedRows"
    [selectionType]="SelectionType.checkbox"
    [selectAllRowsOnPage]="false"
    (select)="onSelect($event)"
  >
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <input
          type="checkbox"
          class="cursor-pointer"
          [checked]="allRowsSelected"
          (change)="selectFn(!allRowsSelected)"
        />
      </ng-template>

      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          type="checkbox"
          class="cursor-pointer"
          [checked]="isSelected"
          (change)="onCheckboxChangeFn($event)"
        />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="50"
      [maxWidth]="50"
      [resizeable]="false"
      [sortable]="false"
    >
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.link") }}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-url-invitation-component
          [invitationId]="row.id"
        ></app-url-invitation-component>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="140"
      [maxWidth]="140"
      [resizeable]="false"
      [sortable]="false"
      headerClass="ngx-datatable-column-with-mat-select"
    >
      <ng-template ngx-datatable-header-template>
        <mat-form-field
          class="mat-select-for-ngx-datatable mat-select-simulation-filter"
        >
          <mat-label>{{ translate.instant("fields.type") }}</mat-label>
          <mat-select
            [(ngModel)]="simulationFilter"
            name="type"
            (selectionChange)="applyFilters()"
          >
            <mat-option
              *ngFor="let isSimulation of isSimulationList"
              [value]="isSimulation.value"
            >
              {{ isSimulation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          *ngIf="simulationFilter == true || simulationFilter == false"
          class="mat-select-clear-button-for-ngx-datatable"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="simulationFilter = undefined; applyFilters()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <h5 class="ml-1">
          <span
            class="badge badge-pill"
            [ngClass]="{
              'badge-info': !row.testcandidat.simulation,
              'badge-warning': row.testcandidat.simulation
            }"
          >
            {{ row.testcandidat.simulation ? "Simulation" : "Invitation" }}
          </span>
        </h5>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="150"
      [maxWidth]="150"
      [resizeable]="false"
      name="dateInvitation"
    >
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.invitationDate") }}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ this.invitationService.formatDate(row.dateInvitation) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [sortable]="false"
      [canAutoResize]="true"
      headerClass="datatable-filter-field"
    >
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="mat-select-for-ngx-datatable">
          <mat-label>{{ translate.instant("invitations.phase") }}</mat-label>
          <mat-select
            [(ngModel)]="phaseFilter"
            name="type"
            (selectionChange)="applyFilters()"
          >
            <mat-option *ngFor="let phase of phasesList" [value]="phase">
              {{ phase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          *ngIf="phaseFilter"
          class="mat-select-clear-button-for-ngx-datatable"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="phaseFilter = ''; applyFilters()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getPhaseCandidature(row) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [canAutoResize]="true" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>
            {{ translate.instant("fields.email") }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="emailFilter"
            (keyup)="applyFilters()"
          />
          <button
            mat-button
            *ngIf="emailFilter"
            class="mat-select-clear-button-for-ngx-datatable"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="emailFilter = ''; applyFilters()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <span class="material-icons datatable-filter-field-icon"
          >filter_alt</span
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getCandidatEmailFromInvitation(row) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="150" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>
            {{ translate.instant("fields.name") }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="nomFilter"
            (keyup)="applyFilters()"
          />
          <button
            mat-button
            *ngIf="nomFilter"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="nomFilter = ''; applyFilters()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <span class="material-icons datatable-filter-field-icon"
          >filter_alt</span
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getCandidatNomFromInvitation(row) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="150" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>
            {{ translate.instant("fields.firstName") }}
          </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="prenomFilter"
            (keyup)="applyFilters()"
          />
          <button
            mat-button
            *ngIf="prenomFilter"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="prenomFilter = ''; applyFilters()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <span class="material-icons datatable-filter-field-icon"
          >filter_alt</span
        >
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getCandidatPrenomFromInvitation(row) }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [canAutoResize]="true"
      [sortable]="false"
      headerClass="ngx-datatable-column-with-mat-select"
    >
      <ng-template ngx-datatable-header-template>
        <mat-form-field class="datatable-filter-field">
          <mat-label>{{
            translate.instant("invitations.questionnaire")
          }}</mat-label>
          <mat-select
            [(ngModel)]="questionnaireFilter"
            name="type"
            (selectionChange)="applyFilters()"
          >
            <mat-option
              *ngFor="let questionnaire of questionnairesList"
              [value]="questionnaire.nom"
            >
              {{ questionnaire.nom }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          *ngIf="questionnaireFilter"
          class="mat-select-clear-button-for-ngx-datatable"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="questionnaireFilter = ''; applyFilters()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.testcandidat.questionnaire.nom }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [canAutoResize]="true"
      [sortable]="true"
      name="candidat.xp"
    >
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.experience") }}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getCandidatExperienceFromInvitation(row) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      [canAutoResize]="true"
      [sortable]="false"
      name="emailStatus"
    >
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.emailStatus") }}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ invitationService.getEmailStatus(row) }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [canAutoResize]="true" name="testcandidat.note">
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.score") }} </span>
        <label class="switch">
          <input
            type="checkbox"
            id="ToggleswitchId"
            class="cursor-pointer"
            [checked]="isSelected"
          />
          <span
            matTooltip="Score Avec/Sans penalité de temps"
            class="slider round"
          ></span>
        </label>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-button-score-component
          [invitation]="row"
        ></app-button-score-component>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [canAutoResize]="true" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <span>{{ translate.instant("invitations.pdfReport") }}</span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <app-button-pdf-component [invitation]="row"></app-button-pdf-component>
      </ng-template>
    </ngx-datatable-column>

    <!-- Cell actions -->
  </ngx-datatable>

  <mat-paginator
    class="mat-paginator-for-ngx-datatable"
    [length]="totalElements"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageIndex]="1"
    (page)="setPage($event)"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
